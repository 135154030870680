/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Frontend WEBARQ
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif; 
    position: relative;
    overflow-x: hidden;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    @include borderRadius(0);
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }

/* structure 
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: flex; flex-flow: row wrap;}
.trigger{ position: absolute; left: 0; top: 0; right: 0; bottom: 0;  height: 2px; display: block; margin: auto;  }

section { position: relative; }
.wrapper {
    width: 1460px;margin: 0 auto;
    &.v2{ padding: 0 280px; @include boxSizing(border-box); }
}
.scrollify{ height: 100vh; @include boxSizing(border-box);  overflow: hidden;}
.pagedown{
    position: absolute; bottom: 3%; left: 0; right: 0; z-index: 99; width: 50px; height: auto; margin: 0 auto;
    cursor: pointer; 
    span {
        display: block; position: relative; text-align: center;background: url(../images/material/scroll-down.png)no-repeat center; 
        width: 50px; height: 15px;
    }


}
.zoom{ 
    width:40px; height: 40px; font-size: 0; border: none; background: #292929 url(../images/material/zoom-in.png) no-repeat center;  
    cursor: pointer;position: absolute; right: 15px; bottom:82px;
    &.out{
        background: #292929 url(../images/material/zoom-out.png) no-repeat center; bottom: 40px;
    }
}

/* std-content
----------------------------------------------------------------------------------------------*/
.std-content{
    ul {
        margin: 0 0 30px 0;
        li {
            list-style: disc; margin: 0 0 10px 20px; padding: 0;font-weight: normal;
            font-style: normal;font-stretch: normal;line-height: 1.3;letter-spacing: normal;text-align: left;
        }  
    }
    ol { 
        margin: 0 0 30px 0;
        li {
            list-style: decimal; margin: 0 0 10px 20px; padding: 0;font-weight: normal;font-style: normal;font-stretch: normal;line-height: 1.3;letter-spacing: normal;text-align: left;
        }  
    }
    table,thead,tbody,tfoot,tr,th,td{
        border: 1px solid grey;
    }
    table{
        th,td{padding: 10px;line-height: 1.3;}
        th{font-weight: bold;}
    }
}


/* general
----------------------------------------------------------------------------------------------*/
.mobile-menu{ display: none; }
.more {
    font-weight: 700; color: #292929; font-size: 15px; display: inline-block; line-height: 24px; cursor: pointer;
    position: relative; @include transition(color ease-out 0.25s); border: none; background: transparent;
    span {
        display: block; position: relative; padding-right:45px; 
        &:after, &:before {content:""; width: 24px; height: 24px; position: absolute; right: 15px; top: 0; bottom: 0; @include borderRadius(50%); @include transition(all ease-out 0.25s);}
        &:before {background: $c_ylow;}
    }
    &:after{content:""; width: 27px; height: 12px; position: absolute; right: 18px; top: 0; bottom: 0; margin: auto; background: url('../images/material/arr-btn.png')no-repeat right center; right: 0; @include transition(all ease-out 0.3s);}
    &:hover {
        @include transition(color ease-out 0.25s);
        span {
            &:before {background: #292929;@include transition(all ease-out 0.25s);}
        }
        &:after {background-position: left center;@include transition(all ease-out 0.3s);}
    }
    &.white{
        color: $c_ylow;
        &:after{
            background:url(../images/material/arr-btn-white.png)no-repeat right center;
        }
        &:hover{
            color: #fff;
            &:after{ background-position:left center; }
            span{
                &:before{ background:#fff; }
            }
        }
    }
    &.back{
        span{
            padding-right: 0; padding-left: 50px;
            &:before{ background:#FCB140; }
            &:after,&:before{ right:auto; left: 15px; }
        }
        &:after{ right: auto; left: 0px; @include transform(rotate(180deg));}
    }
}


/* header
----------------------------------------------------------------------------------------------*/
.logo-header{ 
    position: fixed; left: 0; right: 0; top: 20px; z-index:6; margin: 0 auto; text-align: center;
    opacity: 1; visibility: visible; @include transition(.2s all ease-out);
    &.hidden{ opacity: 0; visibility: hidden; }
}
header {
    position: absolute; z-index: 9; left: 0; right: 0; top: 0;   background: #171717;
    .main-head {
       display: block;
        .wrapper {
            width: auto; margin: 0 40px; 
            .rh_head {
                position: absolute;right: 0; top: 0; right: 40px; padding: 34px 0;
                .lang {
                    float: left;
                    select {
                        font-weight: 700; background: transparent url('../images/material/ic-arrow-mnu.png')no-repeat right center; padding: 0;padding-right: 22px; color: $c_ylow; border: none; margin: 12px 0 0;
                        option {color: $c_ylow; background: #171717;}
                    }
                }
                .src {
                    float: left; margin-left: 30px; position: relative;
                    .ic-src {
                        width: 36px; height: 36px; background: $c_ylow url('../images/material/ic-search.png')no-repeat center; 
                        display: block; position: relative; cursor: pointer;
                        &:after {content: ""; left: -20px; height: 16px; width: 1px; background: $c_ylow; position: absolute; top: 0; bottom: 0; margin: auto;}
                    }
                    
                }
            }
            .form-search{
                position: absolute; right:0px; left:0px;  top:100%; overflow: hidden; width: auto; height: 0;
                input[type='text']{
                    position: relative; width: 100%; border: none; 
                    @include boxSizing(border-box); height: 100%; background:#111 url(../images/material/ic-search-ylw.png)no-repeat 98% center;
                    font-size: 17px; color: rgba(255,255,255,0.7); z-index:80; padding: 10px 65px 10px 20px; @include boxSizing(border-box);
                
                }
                @include placeholder{ font-style: italic; }
                form{ height: 100%; }
            }
            nav {
                position: relative;
                ul {
                    text-align: center;
                    li {
                        display: inline-block; vertical-align: middle; margin: 0 10px;  
                         @include transition(.2s all ease-out); 
                         > a {
                            line-height: 36px; padding: 34px 20px; display: block; color: #fff; text-transform: uppercase; font-size: 15px; position: relative;
                            &:before{
                                content:''; position: absolute; left: 0; right: 0; bottom: 0; margin: 0 auto;
                                width: 0px; height: 5px; background: $c_ylow; @include transition(.2s all ease-out);
                            }
                        }
                        &:hover,.active{
                            a{
                                &:before{ width:100%; }
                            }
                        }
                        
                        &.logo {
                            margin: auto; opacity: 0; visibility: hidden; position: absolute;left: 0; right: 0;
                            width: 130px; z-index: 2; top: 0; height: 82px; bottom: 0;  @include transition(.2s all ease-out);
                            a {
                               /* margin: 0 0;*/ padding: 0;
                                &:before{ content: ''; display: none; }

                                img {display: block; margin-top: 4px;}
                            }
                        }
                        &.hv_child {
                           
                           >a {
                                &:after {content: ""; width: 19px; height: 19px; position: absolute; right: 0; 
                                top: 0; bottom: 0; margin: auto; background: url('../images/material/ic-arrow-mnu.png')no-repeat;}
                           }
                            ul{
                                position: absolute; top: 100%; width: 100%; background: rgba(51,51,51,0.8);  z-index: 81;
                                left: 0px; right: 0; opacity: 0; visibility: hidden; @include transition(.2s all ease-out);
                                @include boxShadow(0 0 20px -10px #000);    -webkit-column-count: 3;  -moz-column-count: 3; column-count: 3;
                                padding: 20px 25px; @include boxSizing(border-box);
                                 column-rule: 1px solid #555;  -moz-column-rule: 1px solid #555; -webkit-column-rule: 1px solid #555;
                                > li{ 
                                    display: block; margin: 0;
                                    a{ 
                                        line-height: 24px; padding:15px 5px 15px 25px; @include boxSizing(border-box); text-align: left; 
                                        font-size: 14px;
                                         &:before{ content: ''; display: none; }
                                    }
                                    &:hover{
                                        a{ color: $c_ylow; }
                                    }
                                    &:nth-child(2){ margin: 0; }
                                    &:nth-child(4){ margin: 0; }
                                }
                            }
                            &:hover{
                                ul{
                                    opacity: 1; visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.fixedheader{ 
        position: fixed;left: 0; top: 0; right: 0; 
        .main-head .wrapper nav ul li{
            &.logo{ opacity: 1; visibility: visible; }
            &:nth-child(2){ margin-right: 100px; }
            &:nth-child(4){ margin-left: 100px; }        
           
        }

    }
}
.stay-wa {
    position: fixed; z-index: 9; right: 80px; bottom: 75px;
    a {
        display: inline-block; width: 48px; height: 48px; @include borderRadius(50%); font-size: 0; margin-left: 15px;
        &.wa {background: #55CD6C url('../images/material/ico-wa.png')no-repeat center;}
        &.phone {background: #FCB140 url('../images/material/ico-phone.png')no-repeat center; display: none;}
    }
}

/* homepage
----------------------------------------------------------------------------------------------*/
.home-slide-section {
        position: relative; height: 100vh;
    .slider-home {position: fixed; top: 0; left: 0; right: 0; z-index: 2; bottom: 0;
        .slide-banner{
            height: 100%;
            .list{ height: 100%; }
            figure {
                height: 100%; background-size: cover; background-position: center;
                img {display: block; height: 100%; object-fit: cover; width: 100%;}
                &:after{
                    content:''; position: absolute; left: 0; right: 0; bottom: 0; top: 0; background: url(../images/material/shd-banner.png)no-repeat center;
                    background-size: cover;
                }
            }
            .caption-text {
                position: absolute; left: 0; right: 0; top: 50%; @include transform(translateY(-50%)); z-index: 2;
                .txt{
                    width: 900px;
                    h5 {color: $c_ylow; font-weight: 300; font-style: italic; font-size: 15px; margin-bottom: 15px; line-height: 20px;}
                    h2 {
                        font-family: $f_popins; font-size: 45px; font-weight: 700; color: #fff; margin-bottom: 20px;line-height: 50px;}
                    p {color: #fff; font-size: 15px; line-height: 20px;}
                    &.search-result-tittle{
                       text-align: center; margin: 0 auto; width: 890px;
                       .input-search{
                            position: relative;
                            input[type='text']{ 
                                width: 100%; @include boxSizing(border-box); height: 45px; 
                                font-size: 15px; line-height: 22px; color: #939393;
                                padding-right: 50px; padding-left: 30px;
                            }
                            input[type='submit']{ 
                                position: absolute; right: 0; top: 0; bottom: 0; font-size: 0; 
                                background: #FFD223 url(../images/material/ic-search.png)no-repeat center;
                                width: 45px; border: none;
                            }
                            @include placeholder{ color: #939393; font-style: italic; }
                       }
                    }
                }
                &.ourproject{
                    text-align: center;
                    .txt{ margin: 0 auto; width: 890px; }
                    p{ max-height: 42px; overflow: hidden; }
                }
            }
            .logo-housing{ margin-bottom: 5px; }
            .slick-dots{
                position: absolute; text-align: center; margin: 0 auto; bottom:20%; left: 0; right: 0;
                li{ 
                    display: inline-block; margin: 0 5px;  position: relative;
                    padding-right: 65px; 
                    &:last-child{ 
                        padding-right:0; 
                        &:after{ display:none; }
                    }
                    &:after{
                        content:''; border-top: 1px dashed #fff; position: absolute; right: -10px; top: 5px; bottom: 0;
                        height: 1px; display: block; width: 100%; margin: auto 0;
                    }
                    button{ 
                        font-size: 0; width: 15px; height: 15px; @include borderRadius(100%); 
                        background: #fff; border:none;
                    }                    
                    &.slick-active{
                        button{
                            background: #FFD223; z-index: 3; cursor: pointer; position: relative;
                            &:after{
                             content:''; position: absolute; width: 29px; height: 29px; @include borderRadius(100%); 
                             border:1px solid #FFD223; margin: auto; top: 0; left: 0; right: 0; bottom: 0;
                             @include transform(translateX(-25%));
                            }
                        }
                        &:after{  }
                    }

                }
            }
        }
    }
    &.search{  }
    &.hidemobile{ opacity: 0;visibility: hidden; }
}
.two-box{ 
    display: table;  
    .lf,.rh{ display: table-cell;vertical-align: middle; }
    .rh{ width: 730px; padding-left: 135px; }
    .ttl{}
    .paddleft {
        .img {display: none;}
    }
}

.home{
    &.home-slide-section{
       position: relative;
       .slider-home{
            .slide-banner{
                figure{ height: 100vh; }
            }
       }
    }
    &.about{
        @extend .after_clear; padding: 135px 0 150px; position: relative;
        &:before{ 
            content:''; position: absolute; left: 0; right: 0; bottom: 0; top: 0; display: block; z-index: -1;
            background: #fff url(../images/material/bg-aboutus.png)no-repeat bottom;
         }
       
        .more{ padding-left: 20px; }
        p{ 
            font-size:15px; padding-left: 20px; position: relative;   line-height: 20px; color: #939393;
            margin-bottom: 80px;
            &:before{ content:''; position: absolute; left: 0; top: 0; width: 2px; background:$c_ylow; height: 27px;  }
        }
    }
    &.benefit{
        background-color: #FFC92E; padding: 60px 0; position: relative;
        .wrapper{ position: relative; @include transform(translateY(-50%)); top: 55%; }
        .tittle{ 
            margin-bottom: 40px; 
            small{ 
                font-size: 15px; font-style: italic; line-height: 22px; color: #2B2B2B; display: block; margin-bottom: 15px; 
                font-weight: 200;
            }    
        }    
        
        .box-circle{
            @extend .flex-list;  
            figure{ 
                margin-bottom: 30px; width:125px; height: 125px; border: 1px solid #171717; @include borderRadius(100%);
                overflow: hidden; position: relative; text-align: center; display: inline-block;
                img{ position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto; @include transition(.2s all ease-out); }
            }

            h5{ font-size: 17px; line-height: 24px; font-weight: bold; color: #333333; margin-bottom: 15px;  }
            .circle{ 
                width: 16%; margin: 0 2%; @include boxSizing(border-box); text-align: center; cursor: default;
                &:hover{
                    figure{
                        img{ @include transform(scale(1.2)); }
                    }
                }
            }
            p{ max-height:100px; overflow: hidden;  font-size: 15px; line-height: 20px; color:#555555;  }
        }
    }
    &.slide-wave{
        position: relative;
        &:before{ 
            content:''; position: absolute; left: 0; right: 0; top: 0; background: url(../images/material/bg-benefit.png)no-repeat center; 
            width: 100%; height: 101px; z-index: 2;
        }
        .desc{ 
            position: absolute; left: 0; right: 0; top: 45%; @include transform(translateY(-45%)); text-align: center; 
            color: #fff; width: 870px; margin: 0 auto; padding: 0 80px; @include boxSizing(border-box); z-index: 4;;
            .logo{ 
                margin-bottom: 30px; display: block; 
                img{ margin: auto; }
            }
        }
        small{ font-size:15px; line-height: 24px; font-style: italic; font-weight: 200; display: block; margin-bottom: 10px;  }
        h3{ font-size: 40px; font-weight: bold;line-height: 52px; font-family: $f_popins; margin-bottom: 20px; color: #fff; }
        p{ font-size: 15px; line-height: 22px; color: #fff;}
        .more{ margin-top: 50px; }
        .list {
            position: relative;
            figure {
                position: relative;
                img{ height: 100%; object-fit: cover; }
                &:before{
                    content:''; position: absolute; left: 0; top: 0; right: 0; bottom: 0; background:rgba(0,0,0,0.4);
                }
            }
        }
    }
    &.newsevent{
        padding: 120px 0 40px; position: relative;
        &:before{ 
            content:''; position: absolute; top: 0; left: 0; right: 0; width: 100%; background:#F0F0F0; height: 451px;   
            z-index: -1;
        }
    }
    &.slider-direksi-qts{
        padding: 140px 0 70px; position: relative;
        @extend .flex-list; align-items: center;
        &:before{ 
            content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; 
            background: url(../images/content/bg-slider-direksi.png)no-repeat center bottom; 
        }
        .text-qoutes{ 
            text-align: center; position: relative; padding: 40px 0;
            P{ 
                font-size:25px; line-height: 40px; font-style: italic; color: #363636;  
                position: relative; padding: 0 20px; @include boxSizing(border-box);
                &:before,&:after{ 
                    content:''; position: absolute; left: 0; top: -60px; background: url(../images/material/petik.png)no-repeat center;
                    width: 72px; height: 140px; z-index: -1;
                }
                &:after{
                    bottom: -80px; top: auto; left: auto; right: 0;
                }
            }
        }
        .slider-direksi{
            @extend .after_clear; padding: 0 170px; @include boxSizing(border-box);
            .list{ 
                display: table; padding: 40px 0; opacity: .5; float: left;
                margin: 0 40px; position: relative; @include transition(.2s all ease-out);
                &:before{
                    content:''; position: absolute; left: -41px; top: 0; bottom: 0; width: 1px; height: 100%; background: #BCBCBC;
                }
            }
            figure{ width: 96px; display: table-cell; padding-right:35px;    }
            .desc{ display: table-cell; vertical-align: middle; }
            h6{ font-size: 20px; line-height: 32px; color: #171717; font-weight: bold;}
            small{ font-size: 15px; line-height: 20px; color: #555555;  }
            .slick-current{
                opacity: 1;
                &:before{
                    background:rgba(188,188,188,0.5);
                }
            }
            .slick-arrow{
                position: absolute; left: 0; top: 0; bottom: 0; font-size: 0; border: none; 
                width: 52px; height: 52px; border:1px solid transparent; @include borderRadius(100%);overflow: hidden;
                margin: auto 0; cursor: pointer; @include transition(.2s all ease-out); background: transparent;
                &:before{
                    content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; @include borderRadius(100%);
                    width: 42px; height: 42px; z-index: 2; @include transition(.2s all ease-out);
                    background-color: rgba(23,23,23,1);
                    background: -moz-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(23,23,23,1)), color-stop(100%, rgba(92,92,92,1)));
                    background: -webkit-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                    background: -o-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                    background: -ms-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                    background: linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171717', endColorstr='#5c5c5c', GradientType=1 );
                    opacity: 0; visibility: hidden; @include transition(.2s all ease-out); background: transparent;
                }
                &:after{
                    content: "";background:url(../images/material/ic-arr-right.png) center no-repeat;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;
                    @include transition(.2s all ease-out); @include transform(rotate(-180deg));
                }
                &.slick-next{
                    left: auto; right: 0; 
                    &:after{
                        @include transform(rotate(0));
                    }
                }
                &:hover{
                    border-color: #FFD223;
                    &:before{
                        background: rgba(255,210,35,1);
                        background: -moz-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                        background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,210,35,1)), color-stop(100%, rgba(252,177,64,1)));
                        background: -webkit-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                        background: -o-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                        background: -ms-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                        background: linear-gradient(135deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd223', endColorstr='#fcb140', GradientType=1 );
                        opacity: 1; visibility: visible;
                    }
                }
            }
        }
    }
    &.form-newsevent{
        padding: 40px 0; background: #1C1C1C; @extend .flex-list; align-items: center;
        &.scrollify{ height: calc(100vh - 300px ); }
        h3{ font-family: $f_popins; color: #fff; font-weight: bold; font-size: 35px; line-height: 42px; }
    }
}

.wrap-dot-bg {
    position: absolute; left: 0; right: 0; bottom: 40px;
    z-index: 5;
    .dot-slider {
        .slick-list {padding-bottom: 14px !important;padding-top: 24px !important;}
        .dots-project {
            position: relative; height: 105px;
            span {
                color: #fff; display: block; text-align: center; padding-bottom: 35px; position: relative; text-align: center; top: -18px; @include transition(all ease-out 0.9s);
                &:after {content: ""; width: 22px; height: 22px; background: #fff; @include borderRadius(50%); display: block; position: absolute;left: 0; right: 0; margin: auto; bottom: 0;}
            }
            &.slick-center {
                text-align: center;
                span {
                    // top: 120px; padding-bottom: 30px;
                    &:after {background: #FFD223;}
                }
            }
            &.prevdiv,
            &.nextdiv {
                span {
                    top: 65px; padding-bottom: 0px; padding-top: 40px; @include transition(all ease-out 0.9s);
                    &:after {top:0; bottom: auto;}
                }
            }
            &:nth-child(5n+1) {
                background: url('../images/material/bg-1.png') no-repeat; background-size: 100%;
                span {
                    top: 30px;
                }
            }
            &:nth-child(5n+2) {
                background: url('../images/material/bg-2.png') no-repeat; background-size: 100%;
                span {
                    padding-top: 30px; padding-bottom: 0; top: 0px;
                    &:after {top: 0; bottom: auto;}
                }
            }
            &:nth-child(5n+3) {
                background: url('../images/material/bg-3.png') no-repeat; background-size: 100%;
                span {top: 58px;}
            }
            &:nth-child(5n+4) {
                background: url('../images/material/bg-4.png') no-repeat; background-size: 100%;
                span {
                    padding-top: 30px; padding-bottom: 0;top: 0px;
                    &:after {top: 0; bottom: auto;}
                }
            }
            &:nth-child(5n+5) {
                background: url('../images/material/bg-5.png') no-repeat; background-size: 100%;
                span {
                    top: 30px;
                }
            }
            // &:nth-child(2),
            // &:nth-child(4) {
            //     span {top: 60px;}
            // }
            // &:nth-child(3){
            //     top: 160px;
            // } 
            // &:nth-child(1),
            // &:nth-child(5) {
            //     span {top: 7px;}
            // }
        }
    }
    .dot-slider {
        @extend .flex-list;
        .dots-project {width: 20%;}
    }
}

/* middle
----------------------------------------------------------------------------------------------*/
.center{ text-align: center; }
.smll-ttl{ font-size: 15px; line-height: 50px; color:$c_ylow; font-style: italic;  }
.ttl{ 
    font-size: 35px; line-height: 40px; color:#363636; font-weight: bold; text-transform: uppercase; margin-bottom: 30px;  
    font-family: $f_popins; word-break: break-word;
}
.inner-content{ 
    padding: 135px 0 150px; position: relative;
    &.bg-bottom{
        &:before{ 
        content:''; position: absolute; bottom: 0; left: 0; right: 0; top: 0;  z-index: -1;
        background: url(../images/content/bg-slider-direksi.png)no-repeat bottom; }
    }
    &.contactus{
        padding: 75px 0 0;
    }
}
.row-tittle{
    display: table; width: 100%;
    .left,.right{ display: table-cell;float: none; vertical-align: bottom; }
    .right{  text-align: right; }
    .left{ width:460px;  }
    h3{ margin-bottom: 0; }

}
.middle{
    padding: 104px 0 0 0; position: relative; z-index: 8; background: #fff; overflow: hidden;
    p{ font-size:15px; color: #939393; line-height: 22px;  }
    h2{ font-size:35px; line-height: 42px; font-weight: bold; color: #363636; margin-bottom: 15px; }
    &.fixed{position: fixed;top:0px;left:0px;bottom:0px;right:0px;}
}
.date{ font-style: italic; font-size: 15px; color:#FCB140; font-weight: 200; @include transition(.2s all ease-in); }
.three-box{
    @extend .flex-list; margin: 50px 0 0;    
    &.newshome{ 
        .list{ margin-bottom: 0; }
    }
    .list{ 
        width: 32%; margin:0 2% 80px 0; @include boxSizing(border-box);  
        position: relative; padding-bottom: 35px;
        &:nth-child(3n){ margin-right: 0; }
        &:hover{
            @include boxShadow(0 0 22px -15px #000); background: $c_ylow; @include transition(.2s all ease-out);
            .date{ color: #393939; }
            .more{
                span{
                    &:after{ background:#FCB140; }
                }
            }
            figure{
                img{ @include transform(scale(1.1)); }
            }
        }
    }
    .text{
        padding: 40px 20px 15px; @include boxSizing(border-box); 
    }
    h4{ 
        margin-bottom:20px; font-family: $f_popins; font-size: 25px; line-height: 32px; font-weight: bold; 
       
    }
    p{ font-size: 15px; line-height: 22px;  color:#555555; max-height: 66px; overflow: hidden; display: none; }
    .row-left-right{ margin-top: 20px; position: absolute; left:20px; bottom: 20px; right: 20px; }
    figure{
        height: 330px; overflow: hidden;
        img{ height: 100%; width: 100%; object-fit: cover; vertical-align: bottom; @include transition(.2s all ease-out);}
    }
    .more{ margin-right: 15px; }
}
.form-middle{
    padding: 0 140px;  margin: 60px 0 40px; @include boxSizing(border-box);
    &.white{
        margin: 120px 0 40px;
        .more{
            color: #171717;
        }
        label{ color: #333; }

        input[type='text'],
        input[type='email'],
        input[type='number'],textarea,select{ color: #171717; }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
          -webkit-text-fill-color: #171717;
        }
    }
    .row{
        margin-bottom: 50px; @include afterclear; 
        &:last-child{
            .col{
                &:after{ display:none; }
            }
        }
    }
    .field{
        position: relative; margin-bottom: 10px;
         &:after{ 
            content:''; position: absolute; right: 0; bottom: 10px; background: url(../images/material/ic-input.png)no-repeat center;
            width: 7px; height: 7px;
         }
    }
    .col{ 
        float: left; width: 100%; position: relative;
        &.half{
            width: 48%; margin-right: 4%;
            &:nth-child(even){ margin-right: 0; }
        }
        &.act{
              label{ top:-15px; @include transition(.2s all ease-out); opacity: 1;}
        }
        &.alert-input{
            input[type='text'],
            input[type='email'],
            input[type='number'],select,textarea{ border-color: red;} 
        }

    }
    &.black{
        input:-webkit-autofill,input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active{
             -webkit-box-shadow: 0 0 0 30px transparent inset !important;
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: #fff;
      transition: background-color 5000s ease-in-out 0s;
    }
    input[type='text'],
    input[type='email'],
    input[type='number']{ 
        width: 100%; @include boxSizing(border-box); background: transparent; border: none; color: #fff;
        border-bottom: 1px solid #E2E2E2; height: 50px; font-size:15px; z-index: 2; position: relative;
        &:focus{ border-color:$c_ylow; }
    }
    textarea{ 
        width: 100%; @include boxSizing(border-box); resize: none; border: none; background: transparent;
        border-bottom: 1px solid #E2E2E2; height: 110px;  font-size:15px; z-index: 2; position: relative;
         &:focus{ border-color:$c_ylow; } 
    }
    select{
        width: 100%; @include boxSizing(border-box);  border: none; font-size: 15px; 
        border-bottom: 1px solid #E2E2E2; height: 50px; background: transparent url(../images/material/ic-arr-down.png)no-repeat 95% center; z-index: 2; position: relative;
         &:focus{ border-color:$c_ylow; }
    }
    .more{ color: #fff; }
    label{
         position: absolute; left: 0; top: 15px; font-size: 15px; line-height: 22px; font-style: italic; 
         color: $c_ylow; @include transition(.2s all ease-out); opacity: .6;
    }
}
.line{ 
    font-size: 17px; line-height: 24px; font-weight: 600;padding-left: 20px; position: relative;  margin-bottom: 15px;
    &:before{ content:''; position: absolute; left: 0; top: 0; width: 2px; height: 27px; background: $c_ylow; }
}
.paddleft{ padding-left: 20px; @include boxSizing(border-box); }
.video-middle{
    margin: 100px 0; position: relative;
    .thumb-video{  
        position: relative; height:auto; 
        img{ width: 100%; }
    }
    .ic-play{
        position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; background: url(../images/material/ic-play-video.png)no-repeat center;
        cursor: pointer; width: 97px; height: 97px;
    }
    .wrap-yt{
        position: absolute; left: 0; top: 0; bottom: 0; right: 0; width: 100%; height: 100%; z-index: 2;
        display: none;
        iframe{ width: 100%; height: 100%; }
    }
    &.home{
        height: auto; 
        .thumb-video{ height: auto; }
    }
}
.filter-news-event{
    a{ 
         font-size:17px; font-weight: 600; color:#272727; 
    }
    ul{
        li{
           line-height:60px; border-top: 1px solid #9A9A9A; border-bottom: 1px solid #9A9A9A;   
            display: inline-block; margin: 0 15px; padding: 0 45px; @include boxSizing(border-box); @include transition(.2s all ease-out);
            &:hover,&.active{
                background:$c_ylow; border-color: $c_ylow;
            }
        }
    }
}
.pagging{
    text-align: center; margin-top:30px;
    a{
        margin: 0 10px; font-size: 17px; color: #555555; padding: 5px; @include boxSizing(border-box);
        @include transition(.2s all ease-out);
        &:hover,&.active{
            color:$c_ylow;
        }
    }
    .arr{
        position: relative; margin: 0 30px 0 0;  width: 52px; height: 52px; padding: 0; display: inline-block;
        vertical-align: middle; border: 1px solid transparent; @include borderRadius(100%);
        &:before{
            content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; @include borderRadius(100%);
            width: 42px; height: 42px; z-index: 2; @include transition(.2s all ease-out);
            background-color: rgba(23,23,23,1);
            background: -moz-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(23,23,23,1)), color-stop(100%, rgba(92,92,92,1)));
            background: -webkit-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: -o-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: -ms-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171717', endColorstr='#5c5c5c', GradientType=1 );
            opacity: 0; visibility: hidden;
        }
        &:after{
            content: "";background:url(../images/material/ic-arr-right.png) center no-repeat;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;
            @include transition(.2s all ease-out); margin: auto; @include transform(rotate(180deg));
        }
        &:hover{
            border-color: $c_ylow;
            &:before{
                background: rgba(255,210,35,1);
                background: -moz-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,210,35,1)), color-stop(100%, rgba(252,177,64,1)));
                background: -webkit-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                background: -o-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                background: -ms-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                background: linear-gradient(135deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd223', endColorstr='#fcb140', GradientType=1 );
                opacity: 1; visibility: visible;
            }
        }
        &.next{
            margin: 0 0 0 30px;
            &:after{ @include transform(rotate(0deg)); }
        }
    }
    span{ color: #555555; }
}
.contact-landing{
    .bg-grey{
        position: relative;
         &:before{ 
            content:''; width: auto; position: absolute; top: -75px; left: -1000px; right:-1000px; height:230px; background:#F0F0F0;  
            z-index: -1;
        }
    }
}
.our-branches{
    padding: 30px 0 105px; margin-top:250px;  @include boxSizing(border-box); position: relative; background: #FFC92E;
    .wrapper{ }
    &:before{
        content:''; position: absolute; bottom: 99%; left: 0; right: 0; background: url(../images/content/bg-ylw-top.png)no-repeat center;
        height: 152px; width: 100%;
    }

}
.slider-address{
    @include afterclear; margin: 60px 0 0; padding:0 150px; @include boxSizing(border-box);
    .list{ width: 25%; float: left; @include boxSizing(border-box); margin-right: 30px;}
    h5{ font-size: 17px; font-weight: bold; margin-bottom: 10px; display: block; color: #333333;  }
    p{ font-size:15px; line-height: 22px; color: #555555;  }
    a{
        display: block;  color: #555; font-size: 15px; line-height: 22px; margin-bottom: 5px;
        padding-left: 25px; position: relative; word-break: break-all;
        &:before{
             content:''; position: absolute; left: 0; top: 2px; background: url(../images/material/ic-phone-bl.png)no-repeat center; 
             width: 18px; height: 18px; 
        }
        &.ic-email{
            &:before{
                background: url(../images/material/ic-email-bl.png)no-repeat center; width: 19px; height: 19px;
            }
        }
    }
    .slick-arrow{
        position: absolute; left: 4%; top: 0; bottom: 0; font-size: 0; border: none; 
        width: 52px; height: 52px; border:1px solid transparent; @include borderRadius(100%);overflow: hidden;
        margin: auto 0; cursor: pointer; @include transition(.2s all ease-out); background: transparent;
        &:before{
            content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; @include borderRadius(100%);
            width: 42px; height: 42px; z-index: 2; @include transition(.2s all ease-out);
            background-color: rgba(23,23,23,1);
            background: -moz-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(23,23,23,1)), color-stop(100%, rgba(92,92,92,1)));
            background: -webkit-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: -o-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: -ms-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171717', endColorstr='#5c5c5c', GradientType=1 );
            opacity: 0; visibility: hidden; @include transition(.2s all ease-out); background: transparent;
        }
        &:after{
            content: "";background:url(../images/material/arrow-direksi.png) center no-repeat;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;
            @include transition(.2s all ease-out);
        }
        &.slick-next{
            left: auto; right: 4%; 
            &:after{
                @include transform(rotate(180deg));
            }
        }
        &:hover{
            border-color: #FCB140;
            &:before{
                background: rgba(255,128,35,1);
                background: -moz-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
                background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,128,35,1)), color-stop(100%, rgba(252,177,64,1)));
                background: -webkit-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
                background: -o-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
                background: -ms-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
                background: linear-gradient(135deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8023', endColorstr='#fcb140', GradientType=1 );
                opacity: 1; visibility: visible;
            }
        }
    }
}
.ourproject-landing{
    position: relative; 
    &:before{ content:''; position: absolute; left: 0; top: -135px; right: 0; background: #FAFAFA; height: auto; z-index: -1; bottom: 0;}
}
.wrap-list-catalog{
    b{ color:#363636;  }
    .list{ 
        padding-bottom:10px; margin-bottom: 25px; border-bottom: 1px solid #DEDEDE;  
        p{font-size: 13px;}
    }
}
.wrap-download{
    background: #FFC92E; border: none;  padding: 10px 25px 10px 10px; @include borderRadius(40px); @include boxSizing(border-box);
    display: table; cursor: pointer;
    .ic-dwnload{
        width: 60px; height: 60px; background: #FCB140; display: table-cell; position: relative; text-align: left;
        @include borderRadius(100%);
        img{ position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; }
    }
    .text{ 
        display: table-cell; vertical-align: middle; padding: 0 10px; @include boxSizing(border-box); text-align: left; 
        small{ font-size: 15px; color:#939393; line-height: 22px;  }
        h5{ font-size: 17px; color: #363636; line-height: 24px; }
    }
}
.sec-gallery{ 
    padding: 95px 0 195px;  
    .center{ margin-bottom: 50px; display: block; }
}
.slider-gallery{
    margin: 0 -15px; padding: 0 40px; @include boxSizing(border-box);
    figure{
        position: relative; overflow: hidden;
        img{ width: 100%; @include transition(.2s all ease-out);}
        &:before{
            content:''; position: absolute; bottom: 0; left: 0; right: 0; @include transition(.2s all ease-out);
            width: 100%; height: 20%; background-size: cover; z-index: 1;
            background: rgba(0, 0, 0, 0.534);@include transition(.2s all ease-out);
        }
        &:after{ content:''; @include transition(.2s all ease-out); }
    }
    .list{ 
        position: relative; width: 25%; float: left; padding: 0 15px; @include boxSizing(border-box); 
        &:hover{
            .hide{
                height:200px;
            }
            figure{
                img{ @include transform(scale(1.1));}
            }
        }
    }
    .desc{
        position: absolute; left: 45px; right: 45px; bottom: 50px;z-index: 2; height: 30px; overflow: hidden;
       h4{ font-size: 16px; font-weight: bold; line-height: 25px; color: #fff; text-transform: uppercase;}
    }
    .hide{
        height: 0; overflow: hidden; @include transition(.3s all ease-out); display: none;
        span{ font-size:16px; color: #fff; display: block; font-weight: bold; line-height: 26px;  }
        p{ font-size:14px; line-height: 24px; color: #fff;  }
    }
    .slick-arrow{
        position: absolute; left: -3%; top: 0; bottom: 0; font-size: 0; border: none; 
        width: 52px; height: 52px; border:1px solid #383838; @include borderRadius(100%);overflow: hidden;
        margin: auto 0; cursor: pointer; @include transition(.2s all ease-out);
        &:before{
            content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; @include borderRadius(100%);
            width: 42px; height: 42px; z-index: 2; @include transition(.2s all ease-out);
            background-color: rgba(23,23,23,1);
            background: -moz-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(23,23,23,1)), color-stop(100%, rgba(92,92,92,1)));
            background: -webkit-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: -o-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: -ms-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            background: linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171717', endColorstr='#5c5c5c', GradientType=1 );
        }
        &:after{
            content: "";background:url(../images/material/arrow-direksi.png) center no-repeat;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;
            @include transition(.2s all ease-out);
        }
        &.slick-next{
            left: auto; right: -3%; 
            &:after{
                @include transform(rotate(180deg));
            }
        }
        &:hover{
            border-color: #FFD223;
            &:before{
                background: rgba(255,210,35,1);
                background: -moz-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,210,35,1)), color-stop(100%, rgba(252,177,64,1)));
                background: -webkit-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                background: -o-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                background: -ms-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                background: linear-gradient(135deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd223', endColorstr='#fcb140', GradientType=1 );
            }
        }
    }
    .slick-dots{
        text-align: center; margin: 25px auto 0; 
        li{ 
            display: inline-block;   margin: 0 7.5px;
            button{ 
                font-size: 0; border: none; width: 12px; height: 12px; @include borderRadius(100%); background: #333; 
                padding: 0;
            }
           &.slick-active{
                button{
                    background: #FFD223; z-index: 3; cursor: pointer; position: relative;
                    &:after{
                     content:''; position: absolute; width: 20px; height: 20px; @include borderRadius(100%); 
                     border:1px solid #FFD223; margin: auto; top: 0; left: 0; right: 0; bottom: 0;
                     @include transform(translateX(-23%));
                    }
                }
            }
        }
    }
}

.sec-box-two{
    position: relative;
    h2{ margin-bottom: 60px; }
    .wrapper{ @include afterclear; }
    .left-box,.right-box{ float: left; width: 50%; position: relative; padding: 120px 0; @include boxSizing(border-box);}
    .right-box{ 
        padding-left: 80px; 
        .text{
            p{ color: #555555; }
        }
    }
    .left-box{ padding-right: 80px; }
    .row{ margin-bottom: 25px;  @include afterclear; width: 100%;}
    .col{
         float: left; width: 48%; margin-right: 4%; display: table; @include afterclear; margin-bottom: 25px;
         &:nth-child(2n){ margin-right: 0; }
    }
    figure{
        width: 72px; height: 72px; @include borderRadius(100%); border: 1px solid #404040;
        position: relative; float: left; 
        span{ 
            display: block; width: 55px; height: 55px; @include borderRadius(100%);
            position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto;

        }
    }
    .wrap-point-box{ @include afterclear; }
    .text{ 
        padding-left: 10px; margin-left: 80px; 
        h5{ font-size: 17px; color:#363636; font-weight: 600; margin-bottom: 5px;  }
        p{ font-size: 15px; color: #939393; }
    }
    .bg{ 
        position: absolute; left: 0; top: 0; bottom: 0; width: 50%; height: 100%; 
        background: #EFF2F6 url(../images/material/bg-fasilitas.png)no-repeat center;
        background-size: cover;
        &.rgh{
            background: #FFC92E url(../images/material/bg-area.png)no-repeat center;
            left: auto; right: 0;   background-size: cover;
        }
    }

}
.error{ font-size: 14px; display: inline-block; color: red; opacity: 0; }
.masterplan{
    padding: 110px 0;
    .center{
        h2{ margin-bottom: 60px; }
    }
    .img-plan{
        img{ cursor: pointer; }
    }
}

.page100vh{
     figure{
        position: relative;  z-index: 1;
        img{ height: 100vh; width: 100%; }
        &:before{
            content:'';
             position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: url(../images/material/shadow-page404.png)no-repeat center;
             background-size: cover; height: 100%; width: 100%;
        }
     }
     h1{ font-size:300px; font-weight: bold;  color: #fff;font-family: $f_popins; margin-bottom:15px; display: block;  }
     .text{
        position: absolute; left: 0; right: 0; top: 50%; z-index: 2;  text-align: center; margin: 0 auto;
        @include transform(translateY(-50%));
    }
     h5{ 
        font-size:25px;  font-weight: bold; text-transform: uppercase; color: #fff; line-height: 32px; 
        margin-bottom: 10px;
    }
     p{ color: #fff; font-size: 20px; ; color:#E8E8E8; line-height: 30px; }
     h2{ color: #fff; font-size: 80px; text-transform: uppercase; font-weight: bold; font-family: $f_popins; margin-bottom: 25px;}
    h3{ 
        font-size: 60px; color: #fff; font-weight: bold; text-transform: uppercase;
        margin-bottom: 25px;
     }
     .more{ margin-top: 30px; }
     &.pageunder{
        .text{ width: 945px; top: 55%; }
     }
     &.pagety{
        .text{ width: 1070px; }
     }
}
.detail-page{
    padding: 110px 0 100px; position: relative;
    &:before{ 
        content:''; position: absolute; left: 0; right: 0; top: 0; width: 100%; background: url(../images/content/bg-detail-page.jpg)no-repeat center;
        height: 552px; z-index: -1;
     }
     .img-detail{
        margin-bottom: 65px;
        img{ width: 100%; }
     }
     p{ font-size: 15px; line-height: 22px; color: #7C7C7C;  }
    h2{ font-size:35px; line-height: 42px; font-weight: bold; font-family: $f_popins; color:#363636; margin-bottom: 15px;   }
}

.end-text-btn{
    padding-top: 35px; border-top: 1px solid #DDDDDD; margin-top: 35px;
    .right{
        @include afterclear;
        span{ font-size:15px; line-height: 20px; text-transform: uppercase; display: inline-block; float: left; padding-top: 10px; margin-right: 15px;  }
        a{
            display: inline-block; margin: 0 5px; width: 39px; height: 39px; 
            border: 1px solid #111; @include borderRadius(100%); position: relative;
            img{ 
                position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; @include transition(.2s all ease-out); 
                overflow: hidden;
            }
            &:hover{
                img{ @include transform(scale(1.1)); }
            }
        }
    }
}
.search-result-landing{
    .tittle-search{ margin-bottom: 35px;  font-family: $f_popins;  }
    .result{ font-weight: 200; font-style: italic;   }
    .list{
        margin-bottom: 25px; padding-bottom: 15px; border-bottom: 1px solid #C6C6C6;
    }
    h5{ margin-bottom: 15px; }
    .result-bold{ background: #FFD223; color: #333; }
}
.contactus-project{
    background: #1C1C1C; padding: 50px 0;
    .box-form-project{
        @include afterclear;
        .left,.right{ width: 48%; margin-right: 4%; }
        .right{ margin-right: 0; }
        iframe{ width: 100%; height:600px;  }
    }
    .form-middle{ padding: 0; }
    h3{ font-weight: bold; color: #fff; font-family: $f_popins; font-size: 35px; }
}
.mob-dropdown{ display: none; }
.tabbing-spek{
   .wrapper{ position: relative; }
    .slider-type{
        @include afterclear; padding: 0 45px;
        .list{ 
            width:120px;  border-top: 1px solid #9A9A9A; border-bottom: 1px solid #9A9A9A;
            margin-right: 25px; height: 100%;
            &:last-child{ margin-right:0; }
            span{ 
                font-size: 17px; font-weight: 500; color: #111;  padding: 15px 10px; @include boxSizing(border-box);
                display: block; text-align: center; cursor: pointer; position: relative; @include transform(translateY(-50%));
                top: 50%;
            }  
        }
        .slick-track{   @extend .flex-list; }
        .slick-current{ 
            background: $c_ylow; border-color: $c_ylow;}
        .slick-arrow{
            position: absolute; left: 0; top: 0; bottom: 0; font-size: 0; border: none; 
            width: 32px; height: 32px; border:1px solid transparent; @include borderRadius(100%);overflow: hidden;
            margin: auto 0; cursor: pointer; @include transition(.2s all ease-out); background: transparent;
            &:before{
                content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; @include borderRadius(100%);
                width: 22px; height: 22px; z-index: 2; @include transition(.2s all ease-out);
                background-color: rgba(23,23,23,1);
                background: -moz-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(23,23,23,1)), color-stop(100%, rgba(92,92,92,1)));
                background: -webkit-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: -o-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: -ms-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171717', endColorstr='#5c5c5c', GradientType=1 );
                opacity: 0; visibility: hidden;
            }
            &:after{
                content: "";background:url(../images/material/ic-arr-right.png) center no-repeat;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;
                @include transition(.2s all ease-out); background-size: 100%;
                width: 10px; margin: auto; @include transform(rotate(180deg));
            }
            &.slick-next{
                left: auto; right: 0; 
                &:after{
                    @include transform(rotate(0));
                }
            }
            &:hover{
                border-color: #FFD223;
                &:before{
                    background: rgba(255,210,35,1);
                    background: -moz-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,210,35,1)), color-stop(100%, rgba(252,177,64,1)));
                    background: -webkit-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: -o-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: -ms-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: linear-gradient(135deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd223', endColorstr='#fcb140', GradientType=1 );
                    opacity: 1; visibility: visible;
                }
            }
        }
    }
    .wrap-tab{
       border-top: 1px solid #E2E2E2; margin-top: 15px;
    }
    .tab-nav{
          text-align: center;   @include boxSizing(border-box);
    }
    .ls-tab{
        @include afterclear; padding-top: 20px; display: none;
        .slick-current{
            opacity: 1; border-color: $c_ylow; font-weight: 600;        
        }
        a{
            font-size: 17px; font-weight: 500; color: #111;  padding: 15px 10px; @include boxSizing(border-box);
            color:#272727; display: inline-block; float: left;  width: 120px; margin-right: 25px;border-top: 1px solid #9A9A9A; border-bottom: 1px solid #9A9A9A;
             opacity: .8; 
        }

        
        .ls-slide{
              padding: 0 45px; position: relative;
              .slick-arrow{
            position: absolute; left: 0; top: 0; bottom: 0; font-size: 0; border: none; 
            width: 32px; height: 32px; border:1px solid transparent; @include borderRadius(100%);overflow: hidden;
            margin: auto 0; cursor: pointer; @include transition(.2s all ease-out); background: transparent;
            &:before{
                content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; @include borderRadius(100%);
                width: 22px; height: 22px; z-index: 2; @include transition(.2s all ease-out);
                background-color: rgba(23,23,23,1);
                background: -moz-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(23,23,23,1)), color-stop(100%, rgba(92,92,92,1)));
                background: -webkit-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: -o-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: -ms-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171717', endColorstr='#5c5c5c', GradientType=1 );
                opacity: 0; visibility: hidden;
            }
            &:after{
                content: "";background:url(../images/material/ic-arr-right.png) center no-repeat;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;
                @include transition(.2s all ease-out); background-size: 100%;
                width: 10px; margin: auto; @include transform(rotate(180deg));
            }
            &.slick-next{
                left: auto; right: 0; 
                &:after{
                    @include transform(rotate(0));
                }
            }
            &:hover{
                border-color: #FFD223;
                &:before{
                    background: rgba(255,210,35,1);
                    background: -moz-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,210,35,1)), color-stop(100%, rgba(252,177,64,1)));
                    background: -webkit-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: -o-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: -ms-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: linear-gradient(135deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd223', endColorstr='#fcb140', GradientType=1 );
                    opacity: 1; visibility: visible;
                }
            }
        }
        }
    }
    .btn-tab{ position: absolute; left: 0; top: 0; width: 525px;}
    .list-type{ @include afterclear; display: none; }
    .box-desc-type{ float: left;  padding-top: 180px; width: 525px; }
    .content-tab-type{
       
    }
    table{
        tr{
            td{
                padding: 5px 0;  font-size: 15px; line-height: 20px; color:#7C7C7c;
                &:first-child{ width:120px; }
            }
        }
    }
    .slide-type{ 
        float: right; width:728px;  position: relative; padding-top: 45px;
        .border-bg{
            position: absolute; left: auto; top: 0; bottom: 0; right: 20px; border: 1px solid $c_ylow;  
            height:600px; display: block; width: 590px; z-index: -1;
            &:after{
                content:''; position: absolute; left: -20px; top: 20px;  right: 20px; bottom: -20px; 
                background: #fff;
            }
        }
        .arrow-slider-custom{
            a{
                position: absolute; left: 0; top: 0; bottom: 0; font-size: 0; width: 42px; height: 42px;
                border:1px solid #383838; @include borderRadius(100%); margin: auto 0; cursor: pointer;
                @include transition(.2s all ease-out);
                &.next{ 
                    left: auto; right: 0; 
                    &:after{
                        @include transform(rotate(180deg));
                    }
                }
                 &:before{
                    content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; @include borderRadius(100%);
                    width: 32px; height: 32px; z-index: 2; @include transition(.2s all ease-out);
                    background-color: rgba(23,23,23,1);
                    background: -moz-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(23,23,23,1)), color-stop(100%, rgba(92,92,92,1)));
                    background: -webkit-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                    background: -o-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                    background: -ms-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                    background: linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171717', endColorstr='#5c5c5c', GradientType=1 );
                }
                &:after{
                    content: "";background:url(../images/material/arrow-direksi.png) center no-repeat;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;
                    @include transition(.2s all ease-out);     margin: auto;
                }
               
                &:hover{
                    border-color: #FFD223;
                    &:before{
                        background: rgba(255,210,35,1);
                        background: -moz-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                        background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,210,35,1)), color-stop(100%, rgba(252,177,64,1)));
                        background: -webkit-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                        background: -o-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                        background: -ms-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                        background: linear-gradient(135deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd223', endColorstr='#fcb140', GradientType=1 );
                    }
                }
            }
        }
    }
    .slider-small{
         z-index: 2; margin: 0 120px; display: none;
        .list{ border:1px solid #939393; margin: 0 20px; cursor: pointer;}
        figure{
            text-align: center; padding: 20px 0;
            img{ text-align: center; margin: 0 auto; }
        }
        .slick-current{ border-color: $c_ylow; }
    }
    .slider-big{
        padding: 0 100px; margin-bottom: 50px;
         .slick-arrow{
            position: absolute; left: 0; top: 0; bottom: 0; font-size: 0; border: none; 
            width: 42px; height: 42px; border:1px solid #383838; @include borderRadius(100%);overflow: hidden;
            margin: auto 0; cursor: pointer; @include transition(.2s all ease-out);
            &:before{
                content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; @include borderRadius(100%);
                width: 32px; height: 32px; z-index: 2; @include transition(.2s all ease-out);
                background-color: rgba(23,23,23,1);
                background: -moz-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(23,23,23,1)), color-stop(100%, rgba(92,92,92,1)));
                background: -webkit-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: -o-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: -ms-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                background: linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171717', endColorstr='#5c5c5c', GradientType=1 );
            }
            &:after{
                content: "";background:url(../images/material/arrow-direksi.png) center no-repeat;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;
                @include transition(.2s all ease-out);     margin: auto;
            }
            &.slick-next{
                left: auto; right: 0; 
                &:after{
                    @include transform(rotate(180deg));
                }
            }
            &:hover{
                border-color: #FFD223;
                &:before{
                    background: rgba(255,210,35,1);
                    background: -moz-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,210,35,1)), color-stop(100%, rgba(252,177,64,1)));
                    background: -webkit-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: -o-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: -ms-linear-gradient(-45deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    background: linear-gradient(135deg, rgba(255,210,35,1) 0%, rgba(252,177,64,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd223', endColorstr='#fcb140', GradientType=1 );
                }
            }
        }
    }
}
.search-result-landing{
   .box-result{ min-height: 450px; }
}
/* popup Style
----------------------------------------------------------------------------------------------*/

.wrap-pop{
    position: fixed; height: 100%; top: 0; left: 0; right: 0; bottom: 0; width: 100%; background:rgba(0,0,0,0.4);
    z-index: 9999999; overflow-y: auto; display: none;
    .pop-inner{
        position: absolute; display: block; width:100%; height: 100%;
        .overlay-pop{
            position: absolute; left: 0; bottom: 0; right: 0; top: 0;  margin: auto;
        }
        .close{
            position: absolute; right: -10px; top: -15px; width:35px; height: 35px; cursor: pointer; 
            z-index: 8; cursor: pointer; background: #FCB140; @include borderRadius(100%); 
            &:after,&:before{
                content:''; width: 20px; height: 4px; background:#111; display: block; @include transform(rotate(45deg)); top: 15px;
                position: absolute; left: 0; right: 0; margin: auto; @incliude borderRadius(5px);
                @include transition(.2s all ease-out);
            } 
            &:before{  @include transform(rotate(-45deg));   }
            &:hover{
                &:after{ @include transform(rotate(-45deg)); }
                &:before{ @include transform(rotate(45deg)); }
            }
        }
        .content-pop{
            width: 900px; margin: 4% auto; position: relative; z-index: 8; background: #fff; @include borderRadius(5px);
            @include afterclear; @include boxShadow(0 0 20px -15px #000);
        }
        .box-form{ }
        figure{
            width: 100%; text-align: center;  margin-bottom: 10px;
            img{ width: 100%;object-fit: cover; }
        }
        .text{ 
            padding: 20px 40px 10px;@include boxSizing(border-box); 
            .tgl{ font-weight: bold; margin-bottom: 10px; display: inline-block; }
        }
        .tittle-video{ font-size: 20px; line-height: 30px; color: #fff; margin-top: 15px; display: block; }
    }
    &.zoomin{
        .pop-inner{
            .content-pop{ 
                margin:7% auto; width: 1200px; padding: 40px 60px 40px 30px; @include boxSizing(border-box);
                @include borderRadius(0);
            }
            figure{ margin-bottom: 0; }
        }
    }
}
#gallery .pop-inner .overflow {display: none;}
.box-list-popup{
    .list-popup{
        display: none;
        &.active{display: block;}
    }
}
/* footer
----------------------------------------------------------------------------------------------*/
footer{
    z-index: 3; position: relative;
    .top{
        padding: 55px 0 60px; background: #1F1F1F url(../images/content/bg-footer.png)no-repeat center;
        background-size: cover; 
        h5{ font-size:17px; font-weight: bold;  color: $c_ylow; line-height: 24px; margin-bottom: 15px; }
        p{ font-size: 15px; line-height: 22px; color:#D6D6D6;  }
        .box{ 
            width:180px; margin-right:210px;   
            &:first-child{
                width:280px;
            }
            &:last-child{ margin-right: 0; }
            &.socmed{
                a{ 
                    margin: 0 5px 0 0; display: inline-block;
                    @include transition(.2s all ease-out);
                    img{  @include transition(.2s all ease-out); }
                    &:hover{
                        opacity:.7; 
                        img{ @include transform(scale(1.1)); }
                    }
                }

            }
        }
        .ft{ 
            padding-left: 22px; position: relative; margin-bottom: 10px; word-break: break-all;
            &:before{ 
                content:''; position: absolute; left: 0; top: 2px; width: 18px; height: 18px;
                background: url(../images/material/ic-phone-ft.png)no-repeat center;
            }
            &.wa{
                &:before{ background: url(../images/material/ic-wa-ft.png)no-repeat center; }
            }
            &.email{
                &:before{ background: url(../images/material/ic-email-ft.png)no-repeat center; }
            }
        }
        a{ color: #D6D6D6; font-size: 15px; line-height: 22px; display: block; }
        .wrap-box{ @extend .flex-list; }
    }
    .bot{ 
        background: #FFD223; padding: 10px 0;
        h4{ font-size:13px; line-height: 20px; color: #292929;  }
    }

    &.hidemobile{opacity: 0;visibility: hidden;}
}